$( document ).ready(function() {

    $.ajaxSetup({ headers: {'X-CSRF-Token': $('meta[name="_token"]').attr('content') } });

    $("table").wrap( "<div class='table-responsive'></div>" ).addClass('table table-hover table-striped table-condensed');

    $("button").addClass('btn');

    $("label").addClass('control-label').fade;

    $("input[type=text],input[type=email],input[type=date],input[type=search],input[type=password], select, textarea").addClass( "form-control" );

    $("input[type=submit]").addClass('btn btn-primary').wrap('<div class="well text-center"></div>');

    $("ul.pagination").wrap('<div class="well text-center"></div>');

    $(".edit").addClass('btn btn-link').prepend('<i class="fa fa-pencil fa-lg"></i> ');

    $(".remove").addClass('btn btn-link').prepend('<i class="fa fa-trash-o fa-lg"></i> ').on('click', function () {
        return confirm('Are you sure you want to remove this ?');
    });

    $(".delete").addClass('btn btn-danger').prepend('<i class="fa fa-trash-o fa-lg"></i> ').on('click', function () {
        return confirm('Are you sure you want to delete this ?');
    });

    $('.message').fadeOut(1850);




});
